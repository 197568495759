import { Entypo, Ionicons } from "@expo/vector-icons";
import {
  CommonActions,
  NavigationProp,
  RouteProp,
  StackActions,
  useNavigation,
  useRoute
} from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { ActivityIndicator, Platform, View } from "react-native";
import { Button, CheckBox, Input, Text } from "react-native-elements";
import QRCode from "react-native-qrcode-svg";
import { RootStackParamsList } from "../navigation";
import {
  CaptureType,
  IStartPaymentRequest,
  PaymentStatus,
  PaymentStatusResponse,
  PaymentType,
  StartPaymentResponse
} from "../services/clients/SecandaPaymentClient";
import {
  abortPaymentAsync,
  capturePaymentAsync,
  getPaymentStatusAsync,
  startPaymentAsync
} from "../services/SecandaMerchantPaymentService";
import { styles } from "./HomeScreen";

export default function PaymentScreen() {
  const navigation = useNavigation<NavigationProp<RootStackParamsList>>();
  const route = useRoute<RouteProp<RootStackParamsList, "payment">>();
  const [amount, setAmount] = useState<string>();
  const [paymentResponse, setPaymentResponse] =
    useState<StartPaymentResponse>();
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatusResponse>();
  const [isStartingPayment, setIsStartingPayment] = useState<boolean>();
  const [isCaptureAutomatic, setIsCaptureAutomatic] = useState<boolean>(true);

  useEffect(() => {
    var intervalId = setInterval(async () => {
      try {
        if (paymentResponse?.id) {
          var paymentStatusResponse = await getPaymentStatusAsync(
            paymentResponse?.id
          );
          setPaymentStatus(paymentStatusResponse);

          if (
            (paymentStatusResponse?.status === PaymentStatus.Aborted ||
              paymentStatusResponse?.status === PaymentStatus.Rejected ||
              paymentStatusResponse?.status === PaymentStatus.Paid ||
              paymentStatusResponse?.status === PaymentStatus.PartiallyPaid) &&
            route.params?.sessionId
          ) {
            navigation.dispatch(
              CommonActions.setParams({
                sessionId: undefined,
                accountId: undefined
              })
            );
          }
        }
      } catch (e) {
        window.alert(e);
      }
    }, paymentStatus?.interval ?? 1000);

    return () => clearInterval(intervalId);
  }, [paymentResponse]);

  async function startPayment() {
    try {
      setIsStartingPayment(true);

      const paymentRequest: IStartPaymentRequest = {
        accountId: route.params?.accountId,
        sessionId: route.params?.sessionId,
        amount: Number(amount),
        currency: "EUR",
        type: PaymentType.Terminal,
        capture: isCaptureAutomatic ? CaptureType.Automatic : CaptureType.Manual
      };
      var payment = await startPaymentAsync(paymentRequest);
      setPaymentResponse(payment);
    } catch (e) {
      window.alert(e);
    } finally {
      setIsStartingPayment(false);
    }
  }

  return (
    <View
      style={{
        paddingHorizontal: 15,
        paddingTop: 20,
        alignSelf: "center",
        flex: 1,
        flexDirection: "column",
        maxWidth: 800,
        width: "100%"
      }}>
      <Button
        containerStyle={{ alignSelf: "flex-start" }}
        type="clear"
        titleStyle={{ color: "#42184F", marginLeft: 5 }}
        icon={<Ionicons name="arrow-back" size={24} color="#42184F" />}
        title="Go back"
        onPress={() => navigation.dispatch(StackActions.popToTop())}></Button>

      <View>
        <View
          style={{
            height: undefined,
            width: "100%",
            flexDirection: "row",
            backgroundColor: "#FFFFFF",
            alignSelf: "center",
            borderRadius: 10,
            paddingHorizontal: 15,
            paddingVertical: 5,
            marginTop: 20
          }}>
          <View
            style={{
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center"
            }}>
            <Text style={[styles.inputAffix, { alignSelf: "flex-start" }]}>
              Amount
            </Text>
          </View>

          <Input
            containerStyle={{
              flex: 1,
              alignSelf: "center",
              backgroundColor: "#FFFFFF",
              borderRadius: 10,
              paddingHorizontal: 20,
              paddingVertical: 5,
              height: 46
            }}
            inputContainerStyle={[
              styles.inputContainer,
              {
                borderBottomWidth: 0,
                height: "100%",
                width: "100%"
              }
            ]}
            inputStyle={{
              textAlign: "right",
              color: "#42184F",
              ...(Platform.OS === "web" && {
                outlineStyle: "none"
              }),
              minWidth: 1
            }}
            keyboardType="decimal-pad"
            placeholder="0.00"
            placeholderTextColor="lightgrey"
            autoCorrect={false}
            onChangeText={(value) => setAmount(value)}
            value={amount}></Input>
          <Text style={styles.inputAffix}>€</Text>
        </View>
      </View>

      <CheckBox
        checkedIcon="check-square"
        containerStyle={{
          backgroundColor: "transparent",
          marginLeft: 0,
          borderWidth: 0
        }}
        checkedColor="#42184F"
        checked={isCaptureAutomatic}
        title="Capture automatic"
        onPress={() => setIsCaptureAutomatic(!isCaptureAutomatic)}></CheckBox>

      <Button
        loading={isStartingPayment}
        containerStyle={[styles.button, { alignSelf: "center", marginTop: 20 }]}
        disabled={!amount}
        buttonStyle={{ backgroundColor: "#42184F" }}
        title={`Generate ${amount ?? 0}€ payment`}
        onPress={startPayment}></Button>

      {paymentResponse && (
        <>
          <View
            style={{
              display: "flex",
              marginTop: 50,
              borderRadius: 20,
              backgroundColor: "#FFFFFF",
              height: 300,
              width: 300,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center"
            }}>
            {paymentStatus?.status === PaymentStatus.Created &&
            !route.params?.sessionId ? (
              <QRCode value={paymentResponse.token} size={200}></QRCode>
            ) : paymentStatus?.status === PaymentStatus.Aborted ||
              paymentStatus?.status === PaymentStatus.Rejected ||
              paymentStatus?.status === PaymentStatus.Expired ? (
              <View
                style={{
                  alignSelf: "center",
                  marginVertical: 20,
                  backgroundColor: "red",
                  width: 150,
                  height: 150,
                  borderRadius: 75,
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                <Entypo name="cross" size={128} color="#FFFFFF" />
              </View>
            ) : paymentStatus?.status === PaymentStatus.Paid ||
              paymentStatus?.status === PaymentStatus.PartiallyPaid ? (
              <View
                style={{
                  alignSelf: "center",
                  marginVertical: 20,
                  backgroundColor: "green",
                  borderRadius: 75,
                  width: 150,
                  height: 150,
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                <Entypo name="check" size={128} color="#FFFFFF" />
              </View>
            ) : (
              <View
                style={{
                  flex: 1,
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingHorizontal: 15
                }}>
                <ActivityIndicator
                  color="#42184F"
                  size="large"></ActivityIndicator>
              </View>
            )}
          </View>

          {paymentStatus && (
            <Text style={{ alignSelf: "center", fontSize: 18, marginTop: 5 }}>
              Current payment status: {paymentStatus.status}
            </Text>
          )}

          {!(
            paymentStatus?.status === PaymentStatus.Aborted ||
            paymentStatus?.status === PaymentStatus.Rejected ||
            paymentStatus?.status === PaymentStatus.Paid ||
            paymentStatus?.status === PaymentStatus.PartiallyPaid
          ) && (
            <>
              <Button
                loading={isStartingPayment}
                containerStyle={[
                  styles.button,
                  { alignSelf: "center", marginTop: 20 }
                ]}
                buttonStyle={{ backgroundColor: "#42184F" }}
                title="Abort"
                onPress={async () => {
                  try {
                    await abortPaymentAsync(paymentResponse?.id);
                  } catch (e) {
                    window.alert(e);
                  }
                }}></Button>

              <Button
                loading={isStartingPayment}
                containerStyle={[
                  styles.button,
                  { alignSelf: "center", marginTop: 20 }
                ]}
                disabled={paymentStatus?.status !== PaymentStatus.Authorized}
                buttonStyle={{ backgroundColor: "#42184F" }}
                title="Capture payment"
                onPress={async () => {
                  try {
                    await capturePaymentAsync(paymentResponse?.id);
                  } catch (e) {
                    window.alert(e);
                  }
                }}></Button>
            </>
          )}
        </>
      )}
    </View>
  );
}
