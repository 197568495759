import { Entypo, Ionicons } from "@expo/vector-icons";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { ActivityIndicator, Platform, View } from "react-native";
import { Button, Text } from "react-native-elements";
import QRCode from "react-native-qrcode-svg";
import { RootStackParamsList } from "../navigation";
import {
  Customer,
  SessionStatus,
  SessionStatusResponse,
  StartSessionResponse
} from "../services/clients/SecandaPaymentClient";
import {
  abortSessionAsync,
  getSessionStatusAsync,
  startSessionAsync
} from "../services/SecandaMerchantPaymentService";
import { styles } from "./HomeScreen";
import SessionDataScreen from "./SessionDataScreen";

export default function SessionScreen() {
  const navigation = useNavigation<NavigationProp<RootStackParamsList>>();
  const [sessionResponse, setSessionResponse] =
    useState<StartSessionResponse>();
  const [sessionStatus, setSessionStatus] = useState<SessionStatusResponse>();
  const [customer, setCustomer] = useState<Customer>();

  useEffect(() => {
    async function getSessionData() {
      try {
        var session = await startSessionAsync();
        setSessionResponse(session);
      } catch (e) {
        window.alert(e);
      }
    }

    getSessionData();
  }, []);

  useEffect(() => {
    var intervalId = setInterval(async () => {
      try {
        if (sessionResponse?.id) {
          var sessionStatusResponse = await getSessionStatusAsync(
            sessionResponse?.id
          );
          setSessionStatus(sessionStatusResponse);
        }
      } catch (e) {
        window.alert(`Error when retrieving the session. ${e}`);
      }
    }, sessionStatus?.interval ?? 1000);

    return () => clearInterval(intervalId);
  }, [sessionResponse]);

  if (
    sessionStatus?.status === SessionStatus.Aborted ||
    sessionStatus?.status === SessionStatus.Rejected ||
    sessionStatus?.status === SessionStatus.Expired
  ) {
    return (
      <View
        style={{
          paddingHorizontal: 15,
          paddingTop: 20,
          alignSelf: "center",
          flex: 1,
          flexDirection: "column",
          maxWidth: 800,
          width: "100%"
        }}>
        <Button
          containerStyle={{ alignSelf: "flex-start" }}
          type="clear"
          titleStyle={{ color: "#42184F", marginLeft: 5 }}
          icon={<Ionicons name="arrow-back" size={24} color="#42184F" />}
          title="Go back"
          onPress={() => navigation.goBack()}></Button>

        <Text style={{ alignSelf: "center", fontSize: 18, marginTop: 5 }}>
          Session was {sessionStatus.status}
        </Text>

        <View
          style={{
            alignSelf: "center",
            marginVertical: 20,
            backgroundColor: "red",
            width: 150,
            height: 150,
            borderRadius: 75,
            justifyContent: "center",
            alignItems: "center"
          }}>
          <Entypo name="cross" size={128} color="#FFFFFF" />
        </View>
      </View>
    );
  }

  if (!sessionResponse || !sessionStatus) {
    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          paddingHorizontal: 15
        }}>
        <ActivityIndicator color="#42184F" size="large"></ActivityIndicator>
      </View>
    );
  }

  return (
    <View
      style={{
        paddingHorizontal: 15,
        paddingVertical: 20,
        alignSelf: "center",
        flex: 1,
        flexDirection: "column",
        maxWidth: 800,
        width: "100%"
      }}>
      <Button
        containerStyle={{ alignSelf: "flex-start" }}
        type="clear"
        titleStyle={{ color: "#42184F", marginLeft: 5 }}
        icon={<Ionicons name="arrow-back" size={24} color="#42184F" />}
        title="Go back"
        onPress={() => navigation.goBack()}></Button>

      <View
        style={[
          {
            display: "flex",
            marginTop: 50,
            borderRadius: 20,
            width: 300,
            height: 300,
            backgroundColor: "#FFFFFF",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center"
          },
          Platform.OS === "web" && {
            shapeRendering: "crispedges"
          }
        ]}>
        {sessionStatus.status === SessionStatus.Paired ? (
          <Entypo name="check" size={128} color="green" />
        ) : (
          <QRCode value={sessionResponse.token} size={200}></QRCode>
        )}
      </View>

      {sessionStatus && (
        <Text style={{ alignSelf: "center", fontSize: 18, marginTop: 5 }}>
          Current session status: {sessionStatus.status}
        </Text>
      )}

      <Button
        disabled={sessionStatus.status === SessionStatus.Paired}
        containerStyle={[styles.button, { alignSelf: "center", marginTop: 30 }]}
        buttonStyle={{ backgroundColor: "#42184F" }}
        title="Abort session"
        onPress={async () => {
          await abortSessionAsync(sessionResponse.id);
        }}></Button>

      <Button
        disabled={sessionStatus.status !== SessionStatus.Paired}
        containerStyle={[
          styles.button,
          { alignSelf: "center", marginTop: 20, marginBottom: 10 }
        ]}
        buttonStyle={{ backgroundColor: "#42184F" }}
        title="Proceed to payment"
        onPress={() => {
          setSessionResponse(undefined);
          navigation.navigate("payment", {
            accountId: customer?.account?.id,
            sessionId: sessionResponse.id
          });
        }}></Button>

      {sessionResponse && sessionStatus.status === SessionStatus.Paired && (
        <SessionDataScreen
          customerCallback={setCustomer}
          params={{ sessionId: sessionResponse?.id }}
        />
      )}
    </View>
  );
}
