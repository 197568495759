import axios, { AxiosInstance } from "axios";
import Constants from "expo-constants";
import {
  IStartPaymentRequest,
  SecandaPaymentClient,
  StartPaymentRequest
} from "./clients/SecandaPaymentClient";

const secandaPaymentClientAxiosInstance: AxiosInstance = axios.create();
const secandaPaymentClient = new SecandaPaymentClient(
  Constants.manifest?.extra?.secandaPaymentAddress,
  secandaPaymentClientAxiosInstance
);

export async function startSessionAsync() {
  var sessionResponse = await secandaPaymentClient.sessionPOST();
  return sessionResponse;
}

export async function getSessionStatusAsync(sessionId: string) {
  var sessionStatus = await secandaPaymentClient.sessionGET(sessionId);
  return sessionStatus;
}

export async function getSessionCustomerAsync(sessionId: string) {
  var customer = await secandaPaymentClient.customer(sessionId);
  return customer;
}

export async function abortSessionAsync(sessionId: string) {
  var aborStSessionStatus = await secandaPaymentClient.abort2(sessionId);
  return aborStSessionStatus;
}

export async function getSessionCostCentersAsync(sessionId: string) {
  var costCenters = await secandaPaymentClient.costcenters(sessionId);
  return costCenters;
}

export async function startPaymentAsync(paymentRequest: IStartPaymentRequest) {
  var paymentResponse = await secandaPaymentClient.paymentPOST(
    new StartPaymentRequest(paymentRequest)
  );
  return paymentResponse;
}

export async function getPaymentStatusAsync(paymentId: string) {
  var paymentStatus = await secandaPaymentClient.paymentGET(paymentId);
  return paymentStatus;
}

export async function capturePaymentAsync(paymentId: string) {
  var capturePaymentStatus = await secandaPaymentClient.capture(paymentId);
  return capturePaymentStatus;
}

export async function abortPaymentAsync(paymentId: string) {
  var abortPaymentStatus = await secandaPaymentClient.abort(paymentId);
  return abortPaymentStatus;
}
