import React, { useEffect, useState } from "react";
import { ActivityIndicator, FlatList, StyleSheet, View } from "react-native";
import { ListItem, Text } from "react-native-elements";
import { SessionDataScreenProps } from "../navigation";
import { CostCenter, Customer } from "../services/clients/SecandaPaymentClient";
import {
  getSessionCostCentersAsync,
  getSessionCustomerAsync
} from "../services/SecandaMerchantPaymentService";

export default function SessionDataScreen({
  params,
  customerCallback
}: {
  params: SessionDataScreenProps;
  customerCallback: (customer: Customer) => void;
}) {
  const [customer, setCustomer] = useState<Customer>();
  const [costCenters, setCostCenters] = useState<CostCenter[]>();
  const [customerLoading, setCustomerLoading] = useState<boolean>(true);

  useEffect(() => {
    async function getSessionData() {
      try {
        var customer = await getSessionCustomerAsync(params.sessionId);
        var costCenters = await getSessionCostCentersAsync(params.sessionId);

        setCustomer(customer);
        customerCallback(customer);
        setCostCenters([...costCenters]);
      } catch (e) {
        console.log(e);
      } finally {
        setCustomerLoading(false);
      }
    }

    getSessionData();
  }, []);

  if (customerLoading) {
    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          paddingHorizontal: 15
        }}>
        <ActivityIndicator color="#42184F" size="large"></ActivityIndicator>
      </View>
    );
  }

  if (!customerLoading && !customer) {
    return (
      <View>
        <Text style={{ color: "#42184F", fontSize: 24, marginTop: 20 }}>
          Customer
        </Text>
        <Text style={{ fontSize: 16 }}>
          The customer paired to the session does not have any account in the
          affiliation
        </Text>
      </View>
    );
  }

  function RenderItem({ item, index }: { item: CostCenter; index: number }) {
    return (
      <View
        style={[
          index === 0 && {
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10
          },
          costCenters && index === costCenters.length - 1
            ? {
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10
              }
            : {
                borderBottomColor: "#F2F2F7",
                borderBottomWidth: 1
              },
          {
            overflow: "hidden",
            backgroundColor: "#FFFFFF"
          }
        ]}
        key={index}>
        <ListItem containerStyle={{ height: 58 }}>
          <ListItem.Content>
            <View
              style={{
                alignSelf: "stretch",
                flexDirection: "row",
                justifyContent: "space-between"
              }}>
              <View
                style={{
                  flexShrink: 1,
                  paddingRight: 5
                }}>
                <Text style={{ color: "#42184F" }} numberOfLines={1}>
                  {item.name}
                  {item.description && (
                    <Text>
                      {" - "}
                      {item.description}
                    </Text>
                  )}
                </Text>
                <Text numberOfLines={1}>{item.status}</Text>
              </View>
              <Text style={{ alignSelf: "center" }} numberOfLines={1}>
                {item.balance.toFixed(2)}€
              </Text>
            </View>
          </ListItem.Content>
        </ListItem>
      </View>
    );
  }

  return (
    <View
      style={{
        paddingHorizontal: 15,
        alignSelf: "center",
        flex: 1,
        flexDirection: "column",
        maxWidth: 800,
        width: "100%"
      }}>
      <Text style={{ color: "#42184F", fontSize: 24, marginTop: 20 }}>
        Customer
      </Text>
      <View style={{ backgroundColor: "#FFFFFF", borderRadius: 10 }}>
        <ListItem style={styles.listItem} bottomDivider>
          <ListItem.Content style={{ flex: 1 }}>
            <ListItem.Title style={{ color: "#42184F" }}>
              First name:
            </ListItem.Title>
          </ListItem.Content>
          <Text style={styles.listTextData}>{customer?.firstName}</Text>
        </ListItem>

        <ListItem style={styles.listItem} bottomDivider>
          <ListItem.Content style={{ flex: 1 }}>
            <ListItem.Title style={{ color: "#42184F" }}>
              Last name:
            </ListItem.Title>
          </ListItem.Content>
          <Text style={styles.listTextData}>{customer?.lastName}</Text>
        </ListItem>

        <ListItem style={styles.listItem} bottomDivider>
          <ListItem.Content style={{ flex: 1 }}>
            <ListItem.Title style={{ color: "#42184F" }}>
              Birthdate:
            </ListItem.Title>
          </ListItem.Content>
          <Text style={styles.listTextData}>
            {customer?.birthdate?.toLocaleDateString()}
          </Text>
        </ListItem>

        <ListItem style={styles.listItem} bottomDivider>
          <ListItem.Content style={{ flex: 1 }}>
            <ListItem.Title style={{ color: "#42184F" }}>
              Category:
            </ListItem.Title>
          </ListItem.Content>
          <Text style={styles.listTextData}>{customer?.category}</Text>
        </ListItem>

        <ListItem style={styles.listItem} bottomDivider>
          <ListItem.Content style={{ flex: 1 }}>
            <ListItem.Title style={{ color: "#42184F" }}>
              Account balance:
            </ListItem.Title>
          </ListItem.Content>
          <Text style={styles.listTextData}>
            {customer?.account?.balance.toFixed(2)}€
          </Text>
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItem.Content style={{ flex: 1 }}>
            <ListItem.Title style={{ color: "#42184F" }}>
              Account status:
            </ListItem.Title>
          </ListItem.Content>
          <Text style={styles.listTextData}>{customer?.account?.status}</Text>
        </ListItem>
      </View>

      {costCenters && costCenters.length > 0 && (
        <>
          <Text style={{ color: "#42184F", fontSize: 24, marginTop: 20 }}>
            Cost Centers
          </Text>

          <FlatList
            style={{
              marginTop: 5,
              marginBottom: 20
            }}
            data={costCenters}
            renderItem={({ item, index }) => {
              return <RenderItem item={item} index={index}></RenderItem>;
            }}
            keyExtractor={(_, index) => index.toString()}></FlatList>
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  listTextData: {
    flex: 1,
    textAlign: "right"
  },
  listItem: {
    padding: 5
  }
});
