import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { Platform } from "react-native";
import HomeScreen from "../screens/HomeScreen";
import PaymentScreen from "../screens/PaymentScreen";
import SessionDataScreen from "../screens/SessionDataScreen";
import SessionScreen from "../screens/SessionScreen";

export type SessionDataScreenProps = {
  sessionId: string;
};

export type PaymentScreenProps = {
  sessionId: string | undefined;
  accountId: string | undefined;
};

export type RootStackParamsList = {
  home: undefined;
  session: undefined;
  sessionData: SessionDataScreenProps;
  payment: PaymentScreenProps | undefined;
};

const RootStack = createStackNavigator<RootStackParamsList>();
export default function Navigation() {
  return (
    <NavigationContainer
      theme={{
        dark: false,
        colors: {
          background: "#F2F2F7",
          primary: "#42184F",
          card: "#FFFFFF",
          text: "#42184F",
          border: "#F2F2F7",
          notification: "#F2F2F7"
        }
      }}>
      <RootStack.Navigator
        screenOptions={{
          headerShown: false
        }}>
        <RootStack.Screen
          component={HomeScreen}
          name="home"
          options={{ title: getTitle("Home") }}></RootStack.Screen>

        <RootStack.Screen
          component={SessionScreen}
          name="session"
          options={{ title: getTitle("Session") }}></RootStack.Screen>

        <RootStack.Screen
          component={SessionDataScreen}
          name="sessionData"
          options={{ title: getTitle("Session Data") }}></RootStack.Screen>

        <RootStack.Screen
          component={PaymentScreen}
          name="payment"
          options={{ title: getTitle("Payment") }}></RootStack.Screen>
      </RootStack.Navigator>
    </NavigationContainer>
  );
}

function getTitle(text: string) {
  return Platform.select({ web: `SECANDA Merchant | ${text}`, default: text });
}
