import { NavigationProp, useNavigation } from "@react-navigation/native";
import React from "react";
import { Platform, StyleSheet, View } from "react-native";
import { Button, Text } from "react-native-elements";
import SecandaBrandingLogoComponent from "../components/SecandaBrandingLogoComponent";
import { RootStackParamsList } from "../navigation";

export default function HomeScreen() {
  const navigation = useNavigation<NavigationProp<RootStackParamsList>>();
  return (
    <View
      style={{
        paddingHorizontal: 15,
        paddingTop: 20,
        alignSelf: "center",
        flex: 1,
        flexDirection: "column",
        maxWidth: 800,
        width: "100%"
      }}>
      <SecandaBrandingLogoComponent
        style={{ alignSelf: "center" }}
        width={250}
        height={150}></SecandaBrandingLogoComponent>

      <Text style={{ marginTop: 50, alignSelf: "center", fontSize: 18 }}>
        Welcome to the SECANDA Merchant PoC. Please, select an action
      </Text>

      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: Platform.OS === "web" ? "space-between" : "center",
          marginTop: 20
        }}>
        <Button
          containerStyle={[
            styles.button,
            Platform.OS === "web" ? { marginLeft: 10 } : { marginBottom: 20 }
          ]}
          buttonStyle={{ backgroundColor: "#42184F" }}
          title="Session"
          onPress={() => navigation.navigate("session")}></Button>

        <Button
          containerStyle={[
            styles.button,
            Platform.OS === "web" && { marginRight: 10 }
          ]}
          buttonStyle={{ backgroundColor: "#651265" }}
          title="Payment"
          onPress={() => navigation.navigate("payment")}></Button>
      </View>
    </View>
  );
}

export const styles = StyleSheet.create({
  button: {
    width: 350,
    borderRadius: 50
  },
  inputAffix: {
    alignSelf: "center",
    fontSize: 18,
    justifyContent: "center"
  },
  inputContainer: {
    justifyContent: "center",
    paddingHorizontal: 0,
    paddingVertical: 0
  }
});
