import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: style */

export default function SecandaBrandingLogoComponent(props: SvgProps) {
  return (
    <Svg
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 340.16 170.08"
      style={{
        enableBackground: "new 0 0 340.16 170.08"
      }}
      xmlSpace="preserve"
      {...props}>
      <Path
        className="st0"
        d="M43.1 129.45c-1.49-6.81-6.8-10.32-15.76-10.32-10.42 0-15.7 4.47-15.7 10.15 0 5.54 3 8.23 10.01 9.55l9.45 1.83c3.66.71 6.4 1.73 6.4 4.98 0 4.77-4.73 6.04-9.25 6.04-7.88 0-11.03-3.71-11.03-8.28h-6.2c-.1 8.94 8.69 12.74 16.67 12.74 9.4 0 16.16-3.45 16.16-10.97 0-6.09-4.42-8.17-9.86-9.29l-9.3-1.88c-4.57-.86-6.96-1.83-6.96-4.98 0-3 2.69-5.28 9.05-5.28 4.64 0 8.87 1.21 10.3 5.7h6.02zm16.47 25.89h27.49v-4.93h-21.8v-11.02h21.7v-4.93h-21.7v-9.55h21.55v-4.98H59.57v35.41zm47.42-17.72c0-8.63 5.24-13.35 13.62-13.35 6.35 0 9.76 3.6 11.33 7.46h6.1c-1.27-7.41-7.57-12.59-17.48-12.59-12.71 0-19.51 7.57-19.51 18.53 0 10.66 7.12 18.48 19.51 18.48 10.01 0 16.67-5.64 17.58-13.05h-5.74c-1.12 3.81-5.13 8.12-11.59 8.12-8.08.01-13.82-5.53-13.82-13.6m67.38 2.33h-13.82l6.86-15.69 6.96 15.69zm6.91 15.39h6.25l-15.75-35.39h-8.18l-15.55 35.39h5.9l4.68-10.82h17.63l5.02 10.82zm42.13 0h8.13v-35.39h-5.29v29.15h-.1l-18.09-29.15h-8.44v35.39h5.28v-29.6h.15l18.36 29.6zm53.41-18.18c0 9.24-3.86 13.3-10.93 13.3h-11.28v-25.79h11.28c6.46 0 10.93 3.55 10.93 12.49m6.1 0c0-11.22-6.56-17.21-16.06-17.21h-17.94v35.39h17.38c10.06 0 16.62-6.15 16.62-18.18m34.13 2.79h-13.82l6.86-15.69 6.96 15.69zm13.16 15.39-15.75-35.39h-8.18l-15.55 35.39h5.9l4.67-10.82h17.64l5.03 10.82h6.24z"
        style={{
          fill: "#42184F"
        }}
      />
      <Path
        d="M193.16 22.22c-6.84-5.6-15.58-8.94-25.11-8.94-21.9 0-39.65 17.74-39.65 39.61 0 7.08 1.88 13.71 5.13 19.46h10.82c-4.37-5.3-7.02-12.06-7.02-19.46 0-16.95 13.75-30.67 30.72-30.67h25.11zM207.7 52.9c0-7.07-1.88-13.7-5.13-19.44h-10.81c4.36 5.3 7.01 12.05 7.01 19.44 0 16.95-13.75 30.69-30.72 30.69h-25.07a39.514 39.514 0 0 0 25.06 8.92c21.91 0 39.66-17.73 39.66-39.61"
        style={{
          fill: "#962a86"
        }}
      />
      <Path
        className="st0"
        d="M188.19 52.9c0 11-9.02 19.92-20.14 19.92-11.12 0-20.14-8.92-20.14-19.92s9.02-19.92 20.14-19.92c11.12 0 20.14 8.92 20.14 19.92"
        style={{
          fill: "#42184F"
        }}
      />
    </Svg>
  );
}
